<template>
  <section>
      Batch
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>